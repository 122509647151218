import React from 'react'
import './Main.scss'
import Logo from 'src/components/Logo'
import Button from 'src/components/Button'
import SwipeSign from 'src/components/SwipeSign'
import { useTranslation } from 'react-i18next';

const MainSection = () => {
    const { t } = useTranslation();

    return <div className="MainSection">        
        <Logo size='large'  className="MainSection__Logo"/>

        <h3 className="Header MainSection__Header">
            {t('Operations efficient as never before')}
        </h3>

        <p className="MainSection__Paragraph">
            {t('We make our customer')}
        </p>

        <Button to="#products" className="MainSection__Button">
            {t('View our products')}
        </Button>  

        <Button to="#about-us" variant="wrapper" className="ScrollDown">
            <SwipeSign label={t('Scroll down')} />
        </Button>
    </div>
}

export default MainSection
