import React from 'react'
import Button from '../Button'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next';

const NavbarElementsWrapper = ({ children, open, setOpen, mobile, dark }) => {
    const { t } = useTranslation();
    
    if (mobile && !open) return <Button
        variant="wrapper"
        className={classNames("NavbarElementsWrapper__OpenButton", {
            "NavbarElementsWrapper__OpenButton--is-dark": dark
        })}
        onClick={() => setOpen(true)}
    >
        <div />
        <div />
        <div />
    </Button>

    if (mobile && open) return <div className={classNames("NavbarElementsWrapper NavbarElementsWrapper--is-mobile", {
        'NavbarElementsWrapper--is-open': open
    })}>
        <Button
            variant="wrapper"
            className="NavbarElementsWrapper__CloseButton"
            onClick={() => setOpen(false)}
        >
            <div />
            <div />
            <div />
        </Button>


        <span className="NavbarElementsWrapper__MenuLabel">
            {t('Menu')}
        </span>

        {children}
    </div>

    return <div className="NavbarElementsWrapper">
        {children}
    </div>

}

export default NavbarElementsWrapper
