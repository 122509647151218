import React from 'react'
import Button from 'src/components/Button'
import classNames from 'classnames'
import { isValidEmail } from 'src/utils';
import { CONTACT_FORM_URL } from 'src/constans';
import 'whatwg-fetch';
import { withTranslation } from 'react-i18next';


class ContactForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            name: '',
            email: '',
            body: '',
            processing: false,
            submitSuccess: false,
            submitFail: false
        }
    }

    handleSubmit = () => {
        const { name, email, body } = this.state;

        const errors = this.validate(this.props.t);
        this.setState({ errors })

        if (Object.entries(errors).length === 0) {
            this.setState({ processing: true })

            const data = {
                nameAndSurname: name.trim(),
                email: email.trim(),
                message: body.trim()
            }

            window.fetch(CONTACT_FORM_URL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
            }).then(res => {
                this.setState({ submitSuccess: true, name: '', email: '', body: '', processing: false });
            }).catch(err => {
                this.setState({ processing: false, submitFail: true })
            })
        }
    }

    changeForm = (field, value) => {
        this.setState({
            [field]: value,
            submitFail: false,
            submitSuccess: false,
            errors: {
                ...this.state.errors,
                [field]: undefined
            },
        })
    }

    validate = (t) => {
        const { email, body } = this.state
        const errors = {}

        if (email.trim().length <= 0) errors.email = t('This field is required')
        else if (!isValidEmail(email.trim())) errors.email = t('Please enter valid email')
        if (body.trim().length <= 0) errors.body = t('This field is required')

        return errors
    }

    render() {
        const { mobile, fullpageApi } = this.props
        const { name, email, body, errors, submitSuccess, processing, submitFail } = this.state
        const { t, i18n } = this.props;
        const isArabic = i18n.language === 'ar';

        return (
            <div className={`ContactForm ${isArabic ? 'rtl' : ''}`}>
                <div className="ContactForm__Field">
                    <label htmlFor="id_first_name" className={`ContactForm__Field__Label ${isArabic ? 'rtl' : ''}`}>
                        {t('First name')}
                        {errors.name && <span className="ContactForm__Error">({errors.name})</span>}
                    </label>

                    <input id="id_first_name" className="Input" placeholder={t("Type name")} disabled={processing}
                        value={name} onChange={e => this.changeForm('name', e.target.value)} />

                </div>

                <div className="ContactForm__Field">
                    <label htmlFor="id_email" className={`ContactForm__Field__Label ${isArabic ? 'rtl' : ''}`}>
                        {t('Email')} <span className="text-error">*</span>
                        {errors.email && <span className="ContactForm__Error">({errors.email})</span>}
                    </label>

                    <input id="id_email" className="Input" placeholder={t("Type address")} disabled={processing}
                        value={email} onChange={e => this.changeForm('email', e.target.value)} />

                </div>

                <div className="ContactForm__Field">
                    <label htmlFor="id_message" className={`ContactForm__Field__Label ${isArabic ? 'rtl' : ''}`}>
                        {t('Your needs')} <span className="text-error">*</span>
                        {errors.body && <span className="ContactForm__Error">({errors.body})</span>}

                    </label>

                    <textarea
                        id="id_message"
                        className="Input"
                        rows={7}
                        placeholder={t("Tell us about your needs")}
                        disabled={processing}
                        value={body}
                        onChange={e => this.changeForm('body', e.target.value)}
                        onFocus={() => {
                            if (mobile){
                                fullpageApi.setAutoScrolling(false)

                            }
                        }}
                        onBlur={() => {
                            if (mobile) {
                                fullpageApi.setAutoScrolling(true)
                                fullpageApi.silentMoveTo('customers')
                                fullpageApi.silentMoveTo('contact')
                            }
                        }}
                    ></textarea>

                </div>

                {submitSuccess ?
                    <span className="ContactForm__SuccessText">
                        {t('Form sent correctly!')}
                    </span> : <div className="ContactForm__SendButtonWrapper">
                        {submitFail && <span className="ContactForm__ErrorText mb-1">
                            {t('Form sent fail!')}
                        </span>}
                        <Button variant="primary" size={mobile ? "medium" : "small"} disabled={processing}
                            fluid={mobile} className={classNames("ContactForm__SendButton")}
                            onClick={this.handleSubmit}>
                            {processing ? 'Processing...' : t('Send')}
                        </Button>
                    </div>
                }
            </div>
        )
    }
}

export default withTranslation()(ContactForm);
