import React from 'react'
import './Mission.scss'
import { LazyLoadImage } from 'react-lazy-load-image-component';

import rocketSrc from 'src/assets/images/rocket.png'

import { useTranslation } from 'react-i18next';


const MissionSection = ({ mobile }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    return <div className="MissionSection">
        <LazyLoadImage
            height={mobile ? 140 : 202}
            src={rocketSrc}
            alt="Apriside company's rocket"
            className="MissionSection__Image"
        />

        <h3 className="Header MissionSection__Header">
            {t('Our Mission')}
        </h3>

        <p className="MissionSection__Paragraph" style={{ direction: isArabic ? 'rtl' : 'ltr' }}>
            {isArabic ? (
                <><bdo dir="ltr">Apriside</bdo> {t('mission')}</>
            ) : (
                t('mission')
            )}
        </p>
    </div>
}

export default MissionSection
