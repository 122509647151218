import React from 'react'
import Card from 'src/components/Card'

import './Customers.scss'

import logo1Src from 'src/assets/images/logo1.png'
import logo2Src from 'src/assets/images/logo2.png'
import logo3Src from 'src/assets/images/logo3.png'
import logo4Src from 'src/assets/images/logo4.png'
import logo5Src from 'src/assets/images/logo5.png'
import SwipeSign from 'src/components/SwipeSign'
import Button from 'src/components/Button'

import { LazyLoadImage } from 'react-lazy-load-image-component';

import { useTranslation } from 'react-i18next';


const CustomersSection = ({ mobile }) => {
    const { t } = useTranslation();

    return <div className="Customers">
        
        <h3 className="Header Customers__Header">
            {t('Trusted By')}   
        </h3>

        <div className="Cards">
            <Card className="Cards__Element">
                <a href="https://rossoffshore.no/" target="_blank">
                    <LazyLoadImage src={logo1Src} alt="Rossoffshore logo" />
                </a>
            </Card>

            <Card className="Cards__Element">
                <a href="https://www.hofor.dk/privat/" target="_blank">
                    <LazyLoadImage src={logo2Src} alt="Hofor logo" />        
                </a>
            </Card>

            <Card className="Cards__Element">
                <a href="http://www.wellsafesolutions.com/" target="_blank">
                    <LazyLoadImage src={logo3Src} alt="Wellsafesolutions logo" />
                </a>
            </Card>

            <Card className="Cards__Element">
                <a href="https://www.total.com/en" target="_blank">
                    <LazyLoadImage src={logo4Src} alt="Total logo" />
                </a>
            </Card>

            <Card className="Cards__Element">
                <a href="https://ofs-portal.com/" target="_blank">
                    <LazyLoadImage src={logo5Src} alt="Ofs-portal logo" />
                </a>
            </Card>
        </div>

        { mobile && <Button to="#contact" variant="wrapper" className="Customers__ScrollDown">
            <SwipeSign label={t("Scroll down")} gray />
        </Button>}
    </div>
}

export default CustomersSection
