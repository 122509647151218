import React from 'react'
import Card from 'src/components/Card'

import './AboutUs.scss'

import improveSrc from 'src/assets/images/improve.png'
import cloudSrc from 'src/assets/images/cloud.png'
import financeSrc from 'src/assets/images/finance.png'

import SwipeSign from 'src/components/SwipeSign';
import Button from 'src/components/Button';
import classNames from 'classnames'

import { LazyLoadImage } from 'react-lazy-load-image-component';

import { useTranslation } from 'react-i18next';


const AboutUsSection = ({ mobile }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    const items = [
        {
            key: 'improve',
            img: improveSrc,
            paragraph: <div style={{ direction: isArabic ? 'rtl' : 'ltr' }}>{isArabic ? <bdo dir="ltr">Apriside</bdo> : ''} {t('about_us_field1_text1_black1')} <strong className="text-primary">{t('about_us_field1_text1_green1')}</strong> {t('about_us_field1_text1_black2')} <strong className="text-primary">{t('about_us_field1_text1_green2')}</strong>
            </div>,
            smallParagraph: <>
                {t('about_us_field1_text2')}
            </>
        },
        {
            key: 'cloud',
            img: cloudSrc,
            paragraph: <>
                {t('about_us_field2_text1_black')} <strong className="text-primary">{t('about_us_field2_text1_green')}</strong>{t('about_us_field2_text1_black2')}
            </>,
            smallParagraph: <>
                {t('about_us_field2_text2')}
            </>
        },
        {
            key: 'finance',
            img: financeSrc,
            paragraph: <>
                {t('about_us_field3_text1_black')} <strong className="text-primary">{t('about_us_field3_text1_green')}</strong> {t('about_us_field3_text1_black2')}
            </>,
            smallParagraph: <>
                {t('about_us_field3_text2')}
            </>
        }
    ]

    const getNextSlideUrlPart = () => {
        if (typeof window === 'undefined') return '/'
        const number = window.location.hash.replace(/^\D+/g, '');

        if (number === '') return '/1'
        else if (+number >= items.length - 1) return ''
        else return `/${+number + 1}`
    }

    return <div className={classNames("AboutSection", {
        'AboutSection--is-mobile': mobile
    })}>
        {mobile ? (<>
            <div className="AboutSection__MobileHeader">
                <h3 className="Header AboutSection__Header">
                    {t('About us')}
                </h3>
            </div>

            <Button to={`#about-us${getNextSlideUrlPart()}`} variant="wrapper" className="AboutSection__SwipeLeft">
                <SwipeSign label={t('Swipe left')} gray direction="horizontal" />
            </Button>

            {items.map(item => <div key={item.key} className="slide">
                <div className="AboutSection__MobileSlideWrapper">
                    <AboutUsSectionItem
                        {...item}
                        key={item.key}
                    />
                </div>
            </div>
            )}
        </>)
            :
            (<>
                <h3 className="Header AboutSection__Header">
                    {t('About us')}
                </h3>
                <div className="AboutSection__Cards">
                    {items.map(item => <AboutUsSectionItem
                        {...item}
                        key={item.key}
                    />)}
                </div>
            </>)}
    </div>
}

export default AboutUsSection



const AboutUsSectionItem = ({ img, paragraph, smallParagraph, mobile }) => {
    return <Card className="AboutSection__Cards__Element">
        {mobile ? <LazyLoadImage
            src={img}
            height={120}
            width={120}
            alt="We improve"
        /> : <LazyLoadImage
                src={img}
                height={120}
                width={120}
                className="AboutSection__Cards__Element__Image"
                alt="We improve"
            />}

        <p className="AboutSection__Cards__Element__MainParagraph">{paragraph}</p>
        <p className="small">{smallParagraph}</p>
    </Card>
}
