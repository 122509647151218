import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ReactComponent as LanguageIconDark } from "src/assets/images/languages_dark.svg";
import { ReactComponent as LanguageIconWhite } from "src/assets/images/languages_white.svg";
import { ReactComponent as LanguageIconGrey } from "src/assets/images/languages_grey.svg";

import './Navbar.scss';

const LanguageDropdown = ({ dark, mobile }) => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const languages = [
    { code: 'en', label: 'English', shortcut: 'ENG' },
    { code: 'fr', label: 'Français', shortcut: 'FR' },
    { code: 'ar', label: 'العربية', shortcut: 'AR' }
  ];

  const currentLanguage = i18n.language || 'en';
  const currentLanguageShortcut = languages.find(lang => lang.code === currentLanguage)?.shortcut;

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setIsOpen(false);
  };

  return (
    <div className="LanguageDropdown">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={classNames("LanguageDropdown__button", {
          'LanguageDropdown__button__isDark': dark,
          'LanguageDropdown__button__isActive': isOpen
        })}
      >
        {mobile ? <LanguageIconGrey /> : dark ? <LanguageIconDark /> : <LanguageIconWhite />}
        <div className='LanguageDropdown__button__text'>{currentLanguageShortcut}</div>
      </button>
      {isOpen && (
        <ul className="DropdownMenu">
          {languages.map((lang) => (
            <li 
              key={lang.code} 
              onClick={() => changeLanguage(lang.code)} 
              className={classNames("DropdownMenu__li", {
                'DropdownMenu__li__isDark': dark
              })}
              >
              {lang.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LanguageDropdown;
