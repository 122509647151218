import React from 'react'
import Card from 'src/components/Card'
import './Contact.scss'

import ContactForm from './ContactForm'
import Button from 'src/components/Button'
import SwipeSign from 'src/components/SwipeSign'
import classNames from 'classnames'

import { useTranslation } from 'react-i18next';


const ContactSection = ({ mobile, fullpageApi, loadBg = false }) => {
    const { t } = useTranslation();
    
    const adressContent = <address className="Informations">
        <div className="Informations__Element">
            <span className="text-primary">{t('Information')}</span>
            <span>Apriside ApS</span>
        </div>

        <div className="Informations__Element">
            <span className="text-primary">{t('Email')}</span>
            <a href="mailto:contact@apriside.com">contact@apriside.com</a>
        </div>

        <div className="Informations__Element">
            <span className="text-primary">{t('Address')}</span>
            <span>Klosterengen 24, 4000 Roskilde, Denmark</span>
        </div>

        <div className="Informations__Element">
            <span className="text-primary">{t('Phone')}</span>
            <a href="tel:4551493012">+45 51493012</a>
        </div>
    </address>

    if (mobile) {
        return <div className={classNames("ContactMobile", {
            'loaded-bg': loadBg
        })}>
            <div className="slide">
                <Card className="ContactMobile__Card">
                    <h3 className="Header">
                        {t('Contact us')}
                    </h3>

                    {adressContent}

                    <Button to="#contact/1" variant="wrapper" className="ContactMobile__Card__Button">
                        <SwipeSign direction="horizontal" label={t("Swipe left")} gray />
                    </Button>
                </Card>
            </div>

            <div className="slide">
                <Card className="ContactMobile__Card">
                    <ContactForm mobile fullpageApi={fullpageApi} />
                </Card>
            </div>
        </div>
    }

    return <div className={classNames("Contact", {
        'loaded-bg': loadBg
    })}>
        <Card className="Contact__Card">
            <div className="Contact__Card__Row">
                <div className="Contact__Card__InfoCol">
                    <h3 className="Header">
                        {t('Contact us')}
                    </h3>

                    {adressContent}
                </div>

                <div className="Contact__Card__FormCol">
                    <ContactForm />
                </div>
            </div>
        </Card>
    </div>
}

export default ContactSection
