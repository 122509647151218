import React from 'react'
import classNames from 'classnames'

const NavbarButton = ({ onClick, children, to, dark, active }) => {
    return (
        <a href={to} onClick={onClick} className={classNames("NavbarButton", {
            'NavbarButton--is-dark': dark,
            'NavbarButton--is-active': active
        })}>
            <p className="NavbarButton__Text">{children}</p>
        </a>
    )
}

export default NavbarButton
