import React from 'react'
import './Footer.scss'
import Logo from '../Logo'
import FooterElement from './FooterElement'

import linkedinImg from 'src/assets/images/instagram.png'
import { COMPANY_LINKEDIN_URL } from 'src/constans'

import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <div className="Footer">
            <div className="Footer__LogoAndLinksWrapper">
                <Logo />

                <div className="Footer__Links">
                    <FooterElement className="Footer__Links__Element" to="#main">{t('Home')}</FooterElement>
                    <FooterElement className="Footer__Links__Element" to="#about-us">{t('About us')}</FooterElement>
                    <FooterElement className="Footer__Links__Element" to="#mission">{t('Our Mission')}</FooterElement>
                    <FooterElement className="Footer__Links__Element" to="#products">{t('Products')}</FooterElement>
                    <FooterElement className="Footer__Links__Element" to="#customers">{t('Customers')}</FooterElement>
                    <FooterElement className="Footer__Links__Element" to="#contact">{t('Contact us')}</FooterElement>
                </div>
            </div>

            <div className="Footer__Socials">
                <a href={COMPANY_LINKEDIN_URL}>
                    <img src={linkedinImg} alt="Apriside company's logo" />
                </a>
            </div>

            <div className="Footer__Copyright">
                © 2019 apriside.com All Rights Reserved.
            </div>
        </div>
    )
}

export default Footer
